<script>
import { app } from '@/main'
import { mapActions } from 'vuex'

import { format } from 'date-fns'
import { APPLICABILITY_OPTIONS } from '../constants'
import { services } from '../services'

const TRANSLATION = {
  header: app.$t('notifications.mediation-plan:audit.loading'),
  leave: app.$t('notifications.mediation-plan:manage.sections.back'),
  save: app.$t('global:form.save'),

  dataTable: {
    header: {
      filter: app.$t('notifications.mediation-plan:audit.datatable.header.1'),
      applicability: app.$t('notifications.mediation-plan:audit.datatable.header.2'),
      enrollment: app.$t('notifications.mediation-plan:audit.datatable.header.3'),
      total: app.$t('notifications.mediation-plan:audit.datatable.header.4'),
      errors: app.$t('notifications.mediation-plan:audit.datatable.header.5'),
      date: app.$t('notifications.mediation-plan:audit.datatable.header.6'),
      details: app.$t('notifications.mediation-plan:audit.datatable.header.7')
    }
  }
}

const BACK_URL = { name: 'notifications.mediation-plan' }

export default {
  name: 'AuditPage',

  components: {
    Action: () => import('@/components/general/Action'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    DataTable: () => import('@/components/general/Datatable'),

    AuditModalDetailList: () => import('../components/AuditModalDetailList')
  },

  data () {
    return {
      logList: [],
      mediationPlanName: TRANSLATION.header,
      isActiveModal: false,
      modalLog: {},
      logListQuery: {
        order: {
          created_at: 'DESC'
        },

        page: 1,
        limit: 30
      }
    }
  },

  computed: {
    getId () {
      return this.$route.params.id
    }
  },

  created () {
    this.setup()
    this.fetchSetup()
  },

  methods: {
    ...mapActions([ 'setFetching', 'setFeedback' ]),

    setup () {
      this.TRANSLATION = TRANSLATION
      this.BACK_URL = BACK_URL
      this.APPLICABILITY_OPTIONS = APPLICABILITY_OPTIONS
    },

    fetchSetup () {
      this.setFetching(true)

      Promise.all([ this.fetchMediationPlan(), this.fetchLogList() ])
        .finally(() => {
          this.setFetching(false)
        })
    },

    async fetchMediationPlan () {
      return services.attemptGetMediationPlan(this.getId)
        .then(({ name }) => {
          this.mediationPlanName = name
        })
    },

    async fetchLogList () {
      const params = {
        id: this.getId,
        query: this.logListQuery
      }

      return services.attemptGetLogList(params)
        .then(({ data }) => {
          this.logList = data
        })
    },

    hasDetailList (detailList) {
      return detailList.length > 0
    },

    getDateFormatted (date) {
      const dateFormatted = format(
        new Date(date),
        "dd/MM/yyyy ' - ' HH:mm"
      )

      return dateFormatted
    },

    openModal (log) {
      this.isActiveModal = true
      this.modalLog = log
    },

    closeModal () {
      this.isActiveModal = false
      this.modalLog = {}
    },

    leave () {
      this.$router.push(BACK_URL)
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="mediationPlanName"
      dark-theme
    >
      <Action
        slot="back"
        class="btn-back"
        :text="TRANSLATION.leave"
        type="button"
        icon="keyboard_backspace"
        @click="leave()"
      />
    </ContentHeader>

    <div class="main-content-inner center">
      <DataTable :items="logList">
        <template slot="thead">
          <tr>
            <th
              class="th"
              width="300"
            >
              {{ TRANSLATION.dataTable.header.filter }}
            </th>
            <th
              class="th text-center"
              width="120"
            >
              {{ TRANSLATION.dataTable.header.applicability }}
            </th>
            <th
              class="th text-center"
              width="120"
            >
              {{ TRANSLATION.dataTable.header.total }}
            </th>
            <th
              class="th text-center"
              width="120"
            >
              {{ TRANSLATION.dataTable.header.enrollment }}
            </th>
            <th
              class="th text-center"
              width="120"
            >
              {{ TRANSLATION.dataTable.header.errors }}
            </th>
            <th
              class="th text-center"
              width="160"
            >
              {{ TRANSLATION.dataTable.header.date }}
            </th>
            <th
              class="th text-center"
              width="100"
            >
              {{ TRANSLATION.dataTable.header.details }}
            </th>
          </tr>
        </template>

        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text">{{ props.item.filter }}</span>
            </td>

            <td class="td text-center">
              <span class="td-text">{{ APPLICABILITY_OPTIONS[props.item.applicability] }}</span>
            </td>

            <td class="td text-center">
              <span class="td-text">{{ props.item.applicabilityQuantity }}</span>
            </td>

            <td class="td text-center">
              <span class="td-text">{{ props.item.enrollmentQuantity }}</span>
            </td>

            <td class="td text-center">
              <span class="td-text">{{ props.item.failures }}</span>
            </td>

            <td class="td text-center">
              <span class="td-text">{{ getDateFormatted(props.item.date) }}</span>
            </td>

            <td
              class="td td-actions text-center"
              width="40"
            >
              <Action
                class="details"
                :disabled="!hasDetailList(props.item.details)"
                :style="{ opacity: hasDetailList(props.item.details) ? '1' : '0.5' }"
                type="button"
                icon="poll"
                @click="openModal(props.item)"
              />
            </td>
          </tr>
        </template>
      </DataTable>
    </div>

    <AuditModalDetailList
      :is-active-modal="isActiveModal"
      :log="modalLog"
      @close-modal="closeModal()"
    />
  </div>
</template>

<style lang="scss" src="../../../assets/scss/Notification.scss" scoped></style>
